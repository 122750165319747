import React from "react";
import { graphql } from "gatsby";
import Link from "gatsby-link";
import Helmet from "react-helmet";
import DefaultLayout from "../layouts/DefaultLayout";
import NewsletterSignupForm from "../components/NewsletterSignupForm";
import RelatedPosts from "../components/RelatedPosts";

const BlogPostTemplate = (props) => {
  const post = props.data.markdownRemark;
  return (
    <DefaultLayout headerClassName="header-has-logo header-short" archiveYears={props.pageContext.archiveYears}>
      <Helmet>
        <title>{post.frontmatter.title} | Keeping It Realtime</title>
      </Helmet>
      <article className="post post-full">
        <h2 className="postTitle">{post.frontmatter.title}</h2>
        <div className="meta cf">
          <span>
            <Link
              to={post.fields.permalink}
              rel="bookmark"
              title="Permalink"
              className="permalink"
            >
              {post.frontmatter.date}
            </Link>
          </span>
          <span>
            <a href={`https://andyet.com/team/${post.frontmatter.author_slug}`}>
              {post.frontmatter.author}
            </a>
          </span>
        </div>
        <div
          className="postContent"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {post.frontmatter.callToAction === true ||
        post.frontmatter.callToAction === null ? (
          <NewsletterSignupForm />
        ) : null}
        {props.pageContext.relatedPosts.length > 0 ? (
          <RelatedPosts posts={props.pageContext.relatedPosts} />
        ) : null}
      </article>
    </DefaultLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        permalink
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        author_slug
        callToAction
      }
    }
  }
`;
